<template>
  <div id="blog-categories" v-loading="loading">
    <h2 class="blog-categories-title">博客分类</h2>
    <div class="blog-categories-wrap">
      <div
        v-for="(category, index) in categories"
        :key="index"
        @click="toCategory(category)"
        class="blog-category-item">
        {{ category.name }}
        <span>{{ category.articleCount }}篇</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blog-categories',
  data () {
    return {
      categories: [],
      loading: true
    }
  },
  methods: {
    toCategory (category) {
      this.$router.push({
        name: 'CategoryArticleList',
        params: { categoryId: category.categoryId }
      })
    },
    getCategories () {
      this.$http({
        url: this.$api.BLOG_GET_CATEGORY_COUNT_URL,
        method: 'GET'
      }).then(response => {
        if (response.status === 'success') {
          for (const item of response.data.result) {
            this.categories.push({
              categoryId: item.categoryId,
              name: item.name,
              articleCount: item.count
            })
          }
          this.loading = false
        } else {
          this.$message.error('服务器好像出错了...')
          this.loading = false
        }
      }).catch(() => {
        this.$message.error('哎呀...网络出错了，刷新一下吧')
        this.loading = false
      })
    }
  },
  created () {
    this.getCategories()
  }
}
</script>

<style lang="stylus" scoped>
#blog-categories
  min-height: calc(100vh - 160px)

.blog-categories-title
  margin: 0 0 20px 15px

.blog-categories-wrap
  max-width: 600px
  display: flex
  flex-direction: row
  flex-wrap: wrap
  align-items: center
  justify-content: center
  margin: 0 auto 60px auto
  .blog-category-item
    padding: 8px 16px
    margin: 5px
    border: 1px solid #eeeeee
    border-radius: 5px
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    transition: all .3s
    font-size: 16px
    @media (max-width: 768px)
      font-size: 14px
    > span
      font-size: 12px
      color: #999999
    &:hover
      background-color: rgba(20, 20, 20, 0.85)
      color: #ffffff
</style>
